<template>
  <div class="mt-10 ml-10">
    <v-row class="display-2 font-weight-bold">
      <v-col cols="12" md="8">
        Ops!
      </v-col>
    </v-row>
    <v-row class="title">
      <v-col cols="12">
        Parece que este fornecedor não possui exatamente o que você procura :(
      </v-col>
    </v-row>
    <v-row class="title" v-if="searchText">
      <v-col cols="12">
        Não se preocupe, tente realizar essa busca com outros fornecedores
        <router-link :to="searchUrl" target="_blank">
          clicando aqui</router-link>,
        ou limpe sua
        <a class="decoration-link" @click="onClickClear">busca aqui</a>.
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    searchText: {
      type: [String],
      required: true,
    },
  },
  computed: {
    searchUrl() {
      return `/q/search/${this.searchText}`;
    },
  },
  methods: {
    onClickClear() {
      this.$emit('clear');
    },
  },
};
</script>
