<template>
  <div
    min-height="450px"
  >
    <v-img v-if="capaPath"
           height="300px"
           :src="capaPath"
           :alt="fornecedor.pessoa.capa"
    />

    <div class="profile-image-contaier">
      <div class="d-sm-flex">
        <v-img v-if="fotoPath"
               class="responsive-image mt-5 d-inline-block"
               max-height="90px"
               height="90px"
               max-width="200px"
               :src="fotoPath"
               :alt="fornecedor.pessoa.nome"
        />
      </div>
    </div>
    <div class="text-profile-container ml-3">
      <div class="align-self-end ml-3">
        <div>
          <span class="display-1 font-weight-bold">{{ fornecedor.pessoa.nome }}</span>
          <px-popover-fornecedor btn-class="mb-2 ml-2" :fornecedor="fornecedor"/>
        </div>
        <v-row dense>
          <v-col cols="12">
            {{ fornecedor.pessoa.cnpj | cnpj }}
            <px-regime-tributario :regime-tributario="fornecedor.pessoa.regimeTributario"/>
          </v-col>
          <v-col cols="12">
            {{ fornecedor.endereco.municipio.nome }} - {{ fornecedor.endereco.estado.sigla }}
          </v-col>
        </v-row>
        <v-row class="mt-3" v-if="fornecedor.pessoa.descricaoPublica">
          <v-col cols="12">
            {{ fornecedor.pessoa.descricaoPublica }}
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import FileUrlPathService from '@/api/file-url-path-service';

export default {
  props: {
    fornecedor: Object,
  },
  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    hasDesricao() {
      return false;
    },
    height() {
      if (this.isSmall) {
        return '';
      }
      let height = 300;
      if (this.hasDesricao) {
        height += 60;
      }
      return `${height}px`;
    },
    fotoPath() {
      if (!this.fornecedor.pessoa.logo) {
        return null;
      }
      return FileUrlPathService.getPath(this.fornecedor.pessoa.logo.path);
    },
    capaPath() {
      if (!this.fornecedor.pessoa.capa) {
        return null;
      }
      return FileUrlPathService.getPath(this.fornecedor.pessoa.capa.path);
    },
  },
};
</script>

<style scoped>
.profile-image-contaier {
  position: relative;
  bottom: 65px;
  left: 30px;
  width: 90%;
}

.text-profile-container {
  position: relative;
  bottom: 50px;
}
</style>
