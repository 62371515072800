<template>
  <v-row class="mr-4">
    <v-spacer/>
    <px-search-text label="Busque por um produto"
                    v-model="searchText"
                    style="max-width: 450px"
                    class="rounded-tag-small"
                    :on-search="emitChange"/>
  </v-row>
</template>

<script>
export default {
  props: {
    initialSearchText: String,
  },
  data() {
    return {
      searchText: null,
    };
  },
  methods: {
    emitChange() {
      this.$emit('change', this.searchText);
    },
    clearText() {
      this.searchText = null;
      this.emitChange();
    },
  },
  created() {
    if (this.initialSearchText) {
      this.searchText = this.initialSearchText;
    }
  },
};
</script>
