<template>
  <div class="d-flex">
    <div>
      <v-img v-if="fotoPath"
             class="responsive-image mt-5 d-inline-block"
             width="190px"
             height="70px"
             :src="fotoPath"
             :alt="fornecedor.pessoa.nome"
      />
    </div>
    <div class="ml-3">
      <v-row>
        <v-col cols="12">
          <span class="display-1 font-weight-bold">{{ fornecedor.pessoa.nome }}</span>
          <px-popover-fornecedor btn-class="mb-2 ml-2" :fornecedor="fornecedor"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          {{ fornecedor.pessoa.cnpj | cnpj }}
          <px-regime-tributario :regime-tributario="fornecedor.pessoa.regimeTributario"/>
        </v-col>
        <v-col cols="12">
          {{ fornecedor.endereco.municipio.nome }} - {{ fornecedor.endereco.estado.sigla }}
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import FileUrlPathService from '@/api/file-url-path-service';

export default {
  props: {
    fornecedor: Object,
  },
  computed: {
    fotoPath() {
      if (!this.fornecedor.pessoa.logo) {
        return null;
      }
      return FileUrlPathService.getPath(this.fornecedor.pessoa.logo.path);
    },
  },
};
</script>
